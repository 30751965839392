import React from "react";
import "./Credit.css"
import { useLocation } from 'react-router-dom';
import { useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Check from "../../../../images/Check.png"
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup';
import close from "../../../../images/close.png"
import { MaskedInput } from 'react-hook-mask';
import { createDefaultMaskGenerator } from 'react-hook-mask';


const schema = yup.object().shape({
    number: yup.string().required('Card number is required'),
    exp_month: yup.number().required('Month is requird'),
    exp_year: yup.number().required('Year is requird'),
    cvc: yup.number().required('Cvv is requird'),
});

const Credit = () => {
    const location = useLocation();
    const response = location.state;
    console.log(response);

    const navigate = useNavigate()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [Errorshow, ErrorsetShow] = useState(false);
    const ErrorhandleClose = () => ErrorsetShow(false);
    const ErrorhandleShow = () => ErrorsetShow(true);

    const [number, setNumber] = useState()
    const [exp_month, set_exp_month] = useState()
    const [exp_year, set_exp_year] = useState()
    const [cvc, set_cvc] = useState()
    const [description, setdescription] = useState('')

    const obj = {
        "orderId": response.payment.orderId,
        "amount": response.payment.totalCost * 100,
        "source": {
            "number": number,
            "exp_month": exp_month,
            "exp_year": exp_year,
            "cvc": cvc
        },
        "currency": "usd",
        "description": description,
        "email": response.payment.email
    }

    console.log(obj);

    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState('')

    const onSubmit = (e) => {
        // e.preventDefault()
        const formData1 = { number, exp_month, exp_year, cvc };
        schema.validate(formData1, { abortEarly: false })
            .then(() => {
                console.log('Form is valid');
                axios.post(process.env.REACT_APP_CARD_PAY, obj)
                    .then((res) => {
                        console.log(res);
                        if (res.status === 202) {
                            handleShow()
                            console.log('ok');
                            console.log(res.orderId);
                        }
                    })
                    .catch((error) => {
                        setError(error.response.data.message);

                        ErrorhandleShow()
                    })
            })
            .catch((error) => {
                const newErrors = {};
                console.log('Form is valid----');
                error.inner.forEach((validationError) => {
                    newErrors[validationError.path] = validationError.message;
                });
                setFormErrors(newErrors);
            });

    }

    const maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999');


    return (
        <>
            <div className='mainbox position-absolute top-50 start-50 translate-middle '>
                <div className='row m-0 p-0'>
                    <h3 className='paytex my-4'>Payment Methods</h3>
                    <h1 className='text-center m-0 p-0 amount mb-3'> ${response.payment.totalCost}</h1>
                    <form onSubmit={(e) => { e.preventDefault(); onSubmit() }}>
                        <div className="row p-0 m-0">
                            <div className="col-sm-12 col-md-8 col-12">
                                <div className="form-group ">
                                    <label className='texhed'>Card number :</label>
                                    <MaskedInput
                                        maskGenerator={maskGenerator}
                                        className="form-control"
                                        onChange={(e) => { setNumber(e) }}
                                        placeholder="0000 0000 0000 0000"
                                    />
                                    <span className={number ? "d-none" : "d-block"}>
                                        {formErrors.number && <span className="text-danger">{formErrors.number}</span>}
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                                <div className="form-group w-100 ">
                                    <label className='texhed'>CVV :</label>
                                    <input type="tel" className="form-control" pattern="\d*" maxlength="4" placeholder="CVV" onChange={(e) => { set_cvc(e.target.value) }} />
                                    <span className={cvc ? "d-none" : "d-block"}>
                                        {formErrors.cvc && <span className="text-danger">{formErrors.cvc}</span>}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="col-sm-12 col-12 col-md-12">
                                <div className="form-group my-3">
                                    <div className="d-flex">
                                        <div className="w-100 ">
                                            <label className='texhed'>
                                                <span className="hidden-xs">Expiry</span>
                                            </label>
                                            <select className="border-0 form-control" onChange={(e) => { set_exp_month(e.target.value) }}>
                                                <option>Month</option>
                                                <option>01</option>
                                                <option>02</option>
                                                <option>03</option>
                                                <option>04</option>
                                                <option>05</option>
                                                <option>06</option>
                                                <option>07</option>
                                                <option>08</option>
                                                <option>09</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                            </select>
                                            <span className={exp_month ? "d-none" : "d-block"}>
                                                {formErrors.exp_month && <span className="text-danger">{formErrors.exp_month}</span>}
                                            </span>
                                        </div>
                                        <div className="w-100 ps-3">
                                            <label className='texhed'>
                                                <span className="hidden-xs">&nbsp;</span>
                                            </label>
                                            <select className="border-0 form-control " onChange={(e) => { set_exp_year(e.target.value) }}>
                                                <option>Year</option>
                                                <option>2023</option>
                                                <option>2024</option>
                                                <option>2025</option>
                                                <option>2026</option>
                                                <option>2027</option>
                                                <option>2028</option>
                                                <option>2029</option>
                                                <option>2030</option>
                                                <option>2031</option>
                                                <option>2032</option>
                                                <option>2033</option>
                                                <option>2034</option>
                                            </select>
                                            <span className={exp_year ? "d-none" : "d-block"}>
                                                {formErrors.exp_year && <span className="text-danger">{formErrors.exp_year}</span>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="col-sm-12 col-md-12 col-12">
                                <div className="form-group my-3">
                                    <label className='texhed'>Discription :</label>
                                    <input type="area" onChange={(e) => setdescription(e.target.value)} className="form-control" placeholder="Discription" />
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-2 col-12 mt-5 mb-5  d-flex justify-content-center mx-auto mt-3" style={{ height: "50px" }}>
                            <button className="btn fw-bold fs-5 btn-primary w-25 " style={{ height: "50px" }} type="submit">Pay</button>
                        </div>
                    </form>
                </div>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}  >
                    <div className='pb-4'>
                        <img src={Check} alt="" className="mx-auto d-block w-50 h-50" />
                        <div className={!response.payment.email ? "d-none" : "d-block"}>
                            <p className='text-center fw-bold'>Invoice sent at email : {response.payment.email}</p>
                        </div>
                        <p className='text-center fw-bold'>Your order number is {response.payment.orderId}</p>
                        <button onClick={() => { handleClose(); navigate('/submitPage') }} className='btn border border-2 w-25 d-block mx-auto'>Ok</button>
                    </div>
                </Modal>
                <Modal show={Errorshow} onHide={ErrorhandleClose} backdrop="static" keyboard={false}  >
                    <div className='pb-4'>
                        <img src={close} alt="" height={'40%'} width={"40%"} className="mx-auto d-block my-3" />
                        <p className='text-center fw-bold'>{error}</p>
                        <button onClick={() => { ErrorhandleClose(); }} className='btn button_red fw-bold border border-2 w-25 d-block mx-auto '>Try again</button>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default Credit