import React from 'react'
import Form from './Form/Forms'
import './SubmitPage.css'
import logo  from '../../images/wise_wood.png'

const SubmitPage = () => {
    return (
        <>
            <div className='p-0 m-0' >
                <div className='text-center head-div position-absolute w-100'>
                    <div className='title-name text-nowrap' style={{height:'70px'}} >
                        <img src={logo} alt="" height="100%" width="150px" style={{objectFit:"cover"}} className='my-auto d-block mx-auto' />
                    </div>
                </div>
                <div className='InvoiceList-H'>
                    <Form />
                </div>
            </div>
        </>
    )
}

export default SubmitPage