import { createContext, useContext, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom"

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const RequirAuth = () => {

    const { user } = useAuth()
    const location = useLocation();

    if (!user) {
        return (
            <Navigate to={{ pathname: "/unauthorized", state: { from: location } }} replace />
        )
    };

    return <Outlet />

};

