import React, { useState } from "react";
import "./Login.css";
import login_image from "../../images/bg-1.png";
import LoginImg from "../../images/Logo.png";
import user_icon from "../../images/user_icon.png";
import password_icon from "../../images/password_icon.png";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import { useNavigate } from "react-router-dom";
import logo from "../../images/wise_wood.png";

const Login = () => {
  const [show, setShow] = useState(false);
  const [email, setUser_name] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const URL = process.env.REACT_APP_LOGIN_API;

  console.log(URL);

  const navigate = useNavigate();

  const hendle_submit = (e) => {
    e.preventDefault();
    let isValid = true;
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Please enter your email");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Please enter your password");
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    console.log(email, password);
    const obj = {
      email: email,
      password: password,
      notificationToken: "web",
      deviceName: "web",
      platform: "web",
    };
    axios
      .post(URL, obj)
      .then((res) => {
        console.log(res, "ress");
        if (
          obj.email === res.data.data.email ||
          obj.password === res.data.data.password
        ) {
          sessionStorage.setItem("authorization", `Bearer ${res.data.token}`);
          if (
            sessionStorage.getItem("authorization", `Bearer ${res.data.token}`)
          ) {
            console.log("yes");
            navigate("/orderHistory");
          }
        } else {
          console.log("no");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message, "err");
        setError(err.response.data.message);
        setShow(true);
      });
  };

  return (
    <>
      <div className="login_page_bg position-relative">
        <div className="position-absolute top-50 start-50 translate-middle w-100">
          <div className="container">
            <div className="row p-0 m-0">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-none d-sm-none d-md-none d-lg-block pe-5">
                <img src={login_image} alt={""} className="login_image" />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100">
                  <div className="logo-div">
                    <img src={logo} alt={""} className="logo_image" />
                  </div>
                  <div className="form_title text-start mt-5">
                    <p className="p-0 m-0 form_title_bold">Welcome </p>
                    <p className="p-0 m-0 form_title_muted">
                      Login to continue
                    </p>
                  </div>
                  <div className="mt-5">
                    <form onSubmit={hendle_submit}>
                      <div>
                        <label className="d-flex">
                          <img
                            src={user_icon}
                            alt={""}
                            width="65px"
                            height="65px"
                          />
                          <input
                            type="email"
                            className={`w-100 border-0 input_type_seting ${
                              emailError && "input-error"
                            }`}
                            placeholder="Enter email"
                            onChange={(e) => {
                              setUser_name(e.target.value);
                              setEmailError("");
                            }}
                          />
                        </label>
                        {emailError && (
                          <p className="text-danger">{emailError}</p>
                        )}
                      </div>
                      <div className="mt-3">
                        <label className="d-flex ">
                          <img
                            src={password_icon}
                            alt={""}
                            width="65px"
                            height="65px"
                          />
                          <input
                            type="password"
                            className={`w-100 border-0 input_type_seting ${
                              passwordError && "input-error"
                            }`}
                            placeholder="Enter password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordError("");
                            }}
                          />
                        </label>
                        {passwordError && (
                          <p className="text-danger">{passwordError}</p>
                        )}
                      </div>
                      <div className="mt-5">
                        <button className="w-100 login_button">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {["Danger"].map((variant, idx) => (
        <Toast
          className="d-inline-block m-1 position-fixed top-0 end-0"
          bg={variant.toLowerCase()}
          key={idx}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header>
            error
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2 me-auto"
              alt=""
            />
          </Toast.Header>
          <Toast.Body className={"text-white fw-bold"}>{error}</Toast.Body>
        </Toast>
      ))}
    </>
  );
};

export default Login;
