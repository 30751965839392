import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from 'papaparse';
import "./InvoiceList.css"
import { useNavigate } from "react-router-dom";

const InvoiceList = () => {
    const [data, setdata] = useState([])
    const [data1, setdata1] = useState()

    const headers = {
        'authorization': `${sessionStorage.getItem('authorization')}`,
    }

    console.log('getData', process.env.REACT_APP_ORDER_HISTORY_ALL_DATA_GET);
    console.log('CsvData (◑‿◐)', process.env.REACT_APP_CSV_DOWNLOAD);

    const navigate = useNavigate()

    const get_all_data = () => {
        axios.get(process.env.REACT_APP_ORDER_HISTORY_ALL_DATA_GET, { headers: headers })
            .then((res) => {
                console.log(res, "123");
                setdata(res.data.data);
                setdata1(res.data.data);
                if (res.status === 200) {
                    console.log('ok');
                } else {
                    navigate('/loginPage')
                }
            })
    }

    useEffect(() => {
        get_all_data()
    }, [])

    // Function to export data as a file
    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const getdata = (m_name) => {
        var str = m_name.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "");
        const year = str.substr(str.length - 4);
        const month = str.substring(0, str.length - 4);
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        console.log(timeZone);
        const obj = {
            "month": month,
            "year": year,
            "timeZone": timeZone
        }
        console.log(obj);
        axios.post(process.env.REACT_APP_CSV_DOWNLOAD, obj, { headers: headers }).then((res) => {
            console.log(res.data.orderList);
            var resData = res.data.orderList
            var delete1 = resData.map((res1) => {
                delete res1._id;
                delete res1.__v;
                res1.createdAt.toString().slice(0, 10);
                return (res1)
            })
            console.log(delete1, "delete1");

            // "heightOfLoad",
            //     "unitOfHieght",
            //     "widthOfLoad",
            //     "unitOfWidth",
            //     "lengthOfLoad",
            //     "unitOfLength",
            //     "photoOfLoad",
            //     "volume",
            //     "selectMaterial",
            //     "totalCost",
            //     "receiverName",
            //     "email",
            //     "ModeOfPayment",
            //     "month",
            //     "year",
            //     "orderId",
            //     "rate",
            //     "tax",
            //     "createdAt",
            //     "updatedAt",

            var csvVal = Papa.unparse(delete1, {
                header: false,
                columns: [
                    "orderId",
                    "nameOfBusiness",
                    "numberPlate",
                    "heightOfLoad",
                    "unitOfHieght",
                    "widthOfLoad",
                    "unitOfWidth",
                    "lengthOfLoad",
                    "unitOfLength",
                    "photoOfLoad",
                    "volume",
                    "selectMaterial",
                    "rate",
                    "totalCost",
                    "ModeOfPayment",
                    "receiverName",
                    "email",
                    "month",
                    "year",
                    "tax",
                    'createdAtDate'
                ], //or array of strings
            });

            var headersVal = Papa.unparse({
                fields: [
                    "Order Id",
                    "Name of business",
                    'Number plate',
                    "Height of load",
                    "Unit of hieght",
                    "Width of Load",
                    "Unit of width",
                    "Length of load",
                    "Unit of length",
                    "Photo of load",
                    "Volume",
                    "Select material",
                    "Rate",
                    "Total cost",
                    "Mode of payment",
                    "Receiver name",
                    "Email",
                    "Month",
                    "Year",
                    "Tax",
                    'CreatedAtDate'
                ], data: []
            });


            var result = headersVal + csvVal;

            console.log(result);

            // const csvData = Papa.unparse(result);
            // console.log(csvData);

            exportData(result, 'data.csv', 'text/csv;charset=utf-8;');
        }).catch((err) => {
            console.log(err);
        });
    }

    const order_history = data.map((data, i) => {
        return (
            <div className="row p-0 m-0 mt-2 mb-2" key={i}>
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="month_button_sm_center">
                        <button className="month_button text-nowrap mt-2 mt-lg-0">{data}</button>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 mt-3 mt-lg-0">
                    <div className="">
                        <button className="Invoice_link_button overflow-auto" onClick={(e) => { e.preventDefault(); getdata(data) }}>
                            <p className="text-start p-0 m-0 ms-0 ms-sm-0 ms-md-0 ms-lg-5 text-nowrap">Download link to download excel data</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    })
    return (
        <>
            {!data1 ?
                <div className="spinner">
                    <span>Loading...</span>
                    <div className="half-spinner"></div>
                </div> :
                <div className='h-100 w-100'>
                    <div className='h-100 padding_set'>
                        <div className="h-100 overflow-auto position-relative">
                            {order_history}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default InvoiceList;
