import React from 'react';
import InvoiceList from './InvoiceList/InvoiceList';
import './OrderHistory.css'

const OrderHistory = () => {
    const month = new Date().getMonth()
    console.log(month);
    
    return (
        <>
            <div className='p-0 m-0' >
                <div className='text-center head-div position-absolute w-100'>
                    <p className='title-name text-nowrap'>Order History</p>
                </div>
                <div className='InvoiceList-H'>
                    <InvoiceList />
                </div>
            </div>
        </>
    )

}
export default OrderHistory;