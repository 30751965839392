import React, { useEffect, useState } from "react";
import "./Forms.css";
import UploadControl from "./inputTypeFile/UploadControl";
import Image_uploade_icon from "../../../images/input_type_image.png";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Check from "../../../images/Check.png";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup.object().shape({
  Number_plate: yup.string().required("Name is required"),
  Height_of_load: yup.number().required("Height is required"),
  Width_of_load: yup.number().required("Width is required"),
  Length_of_load: yup.number().required("Length is required"),
  Unit_Height_of_load: yup.string().required("Unit of height is required"),
  Unit_Width_of_load: yup.string().required("Unit of width is required"),
  Unit_Length_of_load: yup.string().required("Unit of Length is required"),
  // text: yup.string().required('Photo of load is required'),
  Email: yup.string().email("Invalid email"),
  Mode_of_payment: yup.string().required("Mode of payment is required"),
  Select_material: yup.string().required("Select material is required"),
  // Receiver_name: yup.string().required('Receiver name is required'),
});

const Forms = () => {
  const [Name_of_business, setName_of_business] = useState("");
  const [Number_plate, setNumber_plate] = useState("");
  const [Height_of_load, setHeight_of_load] = useState();
  const [Unit_Height_of_load, setUnit_Height_of_load] = useState("Linear feet");
  const [Width_of_load, setWidth_of_load] = useState();
  const [Unit_Width_of_load, setUnit_Width_of_load] = useState("Linear feet");
  const [Length_of_load, setLength_of_load] = useState();
  const [Unit_Length_of_load, setUnit_Length_of_load] = useState("Linear feet");
  // const [Photo_of_load, setPhoto_of_load] = useState('')
  const [Volume, setVolume] = useState("");
  const [Select_material, setSelect_material] = useState("");
  const selectRef = React.useRef(null);
  const [Rate, setRate] = useState("");
  const [Total_cost, setTotal_cost] = useState("");
  const [Mode_of_payment, setMode_of_payment] = useState("");
  const [Email, setEmail] = useState("");
  const [Receiver_name, setReceiver_name] = useState("");
  const [id, setId] = useState("");
  const [email, Setemail] = useState("");
  const [cashError, setCashError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = React.useState(null);
  const imageInputRef = React.useRef();

  const [checkd, setCheckd] = useState(false);
  const [checkd1, setCheckd1] = useState(false);

  console.log(image);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleSelectChange(e) {
    const { value, options } = e.target;
    const text = options[e.target.selectedIndex].text;
    console.log("Selected value: ", value);
    console.log("Selected text: ", text);
    setSelect_material(text);
    setRate(value);
  }

  const [text, setText] = useState("");

  //   const options = [
  //     { group: "Waste" },
  //     { value: "8", name: "Green" },
  //     { value: "120", name: "Trash" },
  //   ];
  const options = [
    {
      categoryName: "Material",
      children: [
        {
          label: "Green",
          value: 8,
        },
        {
          label: "Trash",
          value: 120,
        },
      ],
    },
    {
      categoryName: "Compost",
      children: [
        {
          label: "Screened",
          value: 32,
        },
        {
          label: "Unscreened",
          value: 20,
        },
      ],
    },
  ];

  useEffect(() => {
    var height = Height_of_load;
    var width = Width_of_load;
    var length = Length_of_load;

    if (Unit_Height_of_load === "Linear feet") {
      height = height * 12;
    }
    if (Unit_Width_of_load === "Linear feet") {
      width = width * 12;
    }
    if (Unit_Length_of_load === "Linear feet") {
      length = length * 12;
    }
    var area = length * height * width;
    var area_in_cubic_yard = area / 46656 || 0;

    setVolume(area_in_cubic_yard.toFixed(3));
    var val = Volume * Rate;
    setTotal_cost(val.toFixed(2));
    console.log("hi");
  }, [
    Height_of_load,
    Width_of_load,
    Length_of_load,
    Unit_Width_of_load,
    Unit_Height_of_load,
    Unit_Length_of_load,
    Volume,
    Rate,
    Total_cost,
    Select_material,
    Receiver_name,
    text,
    Mode_of_payment,
  ]);

  // const handleAddBanner = ({ target: { files } }) => {
  //     const loadedImage = files[0];
  //     setPhoto_of_load(loadedImage);
  //     setText(loadedImage.name)
  // };

  const form = () => {
    setName_of_business("");
    setNumber_plate("");
    setHeight_of_load("");
    setUnit_Height_of_load("Linear feet");
    setWidth_of_load("");
    setUnit_Width_of_load("Linear feet");
    setLength_of_load("");
    setUnit_Length_of_load("Linear feet");
    // setPhoto_of_load("")
    setVolume("");
    setSelect_material(options[0].name);
    setRate("");
    setTotal_cost("");
    setMode_of_payment("");
    setEmail("");
    setReceiver_name("");
    setId("");
    Setemail("");
    setText("");
    imageInputRef.current.value = "";
    selectRef.current.value = options[0].value;
    selectRef.current.name = options[0].name;
    setImage(null);
    setCheckd1(false);
    setCheckd(false);
  };

  const [formErrors, setFormErrors] = useState({});

  const naviget = useNavigate();

  const formSubmit = (e) => {
    var formData = new FormData();
    formData.append("nameOfBusiness", Name_of_business);
    formData.append("numberPlate", Number_plate);
    formData.append("heightOfLoad", Height_of_load);
    formData.append("widthOfLoad", Width_of_load);
    formData.append("lengthOfLoad", Length_of_load);
    formData.append("unitOfHieght", Unit_Height_of_load);
    formData.append("unitOfWidth", Unit_Width_of_load);
    formData.append("unitOfLength", Unit_Length_of_load);
    formData.append("volume", Volume);
    formData.append("selectMaterial", Select_material);
    formData.append("rate", Rate);
    formData.append("totalCost", Total_cost);
    formData.append("receiverName", Receiver_name);
    formData.append("photoOfLoad", image);
    formData.append("ModeOfPayment", Mode_of_payment);
    formData.append("email", Email);
    const formData1 = {
      Number_plate,
      Height_of_load,
      Width_of_load,
      Length_of_load,
      Unit_Height_of_load,
      Unit_Width_of_load,
      Unit_Length_of_load,
      text,
      Email,
      Mode_of_payment,
      Select_material,
    };
    schema
      .validate(formData1, { abortEarly: false })
      .then(() => {
        console.log("Form is valid");

        if (Mode_of_payment === "Cash") {
          if (!Receiver_name) {
            setCashError("Receiver name is required");
          } else {
            axios
              .post(process.env.REACT_APP_ORDER_SUBMIT, formData)
              .then((res) => {
                console.log(res);

                if (res.data.status === "success") {
                  handleShow();
                  Setemail(res.data.payment.email);
                  setId(res.data.payment.orderId);
                  setIsLoading(false);
                } else {
                  setIsLoading(true);
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(true);
              });
            setFormErrors({});
          }
        }
        if (Mode_of_payment === "Card") {
          axios
            .post(process.env.REACT_APP_ORDER_SUBMIT, formData)
            .then((res) => {
              setIsLoading(true);
              console.log(res);
              if (res.data.status === "success") {
                setIsLoading(false);
                handleShow();
                Setemail(res.data.payment.email);
                setId(res.data.payment.orderId);
                if (Mode_of_payment === "Card") {
                  console.log("is a card");
                  naviget("/Card", { state: res.data });
                }
              } else {
                setIsLoading(true);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(true);
            });
          setFormErrors({});
        }
      })
      .catch((error) => {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFormErrors(newErrors);
      });
  };
  return (
    <>
      <div className="h-100 w-100">
        <form
          className="h-100 overflow-auto position-relative"
          onSubmit={(e) => {
            e.preventDefault();
            formSubmit();
          }}
        >
          <div className="row p-0 m-0 mx-2 h-100 pt-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <label className="w-100">
                <span className="label_text">Name of business :</span>
                <div>
                  <input
                    type="text"
                    className="w-100 input_types"
                    value={Name_of_business}
                    onChange={(e) => {
                      setName_of_business(e.target.value);
                    }}
                    placeholder="Enter name of business"
                  />
                </div>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3 mt-sm-3 mt-md-3 mt-lg-0">
              <label className="w-100">
                <span className="label_text">Number plate :</span>
                <div>
                  <input
                    type="text"
                    className="w-100 input_types"
                    placeholder="Enter number plate"
                    value={Number_plate}
                    onChange={(e) => {
                      setNumber_plate(e.target.value);
                    }}
                    onInvalid={(F) =>
                      F.target.setCustomValidity("Enter number plate ")
                    }
                    onInput={(F) => F.target.setCustomValidity("")}
                    required
                  />
                  <span className={Number_plate ? "d-none" : "d-block"}>
                    {formErrors.Number_plate && (
                      <span className="text-danger">
                        {formErrors.Number_plate}
                      </span>
                    )}
                  </span>
                </div>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-3">
              <label className="w-100">
                <span className="label_text">Height of load :</span>
                <div className="select_border w-100 d-flex p-2">
                  <input
                    type="number"
                    className="border-0 w-50 select_text"
                    value={Height_of_load}
                    onChange={(e) => {
                      setHeight_of_load(e.target.value);
                    }}
                    onInvalid={(F) =>
                      F.target.setCustomValidity("Enter height of load ")
                    }
                    onInput={(F) => F.target.setCustomValidity("")}
                    required
                  />
                  <div className="line"></div>
                  <select
                    className="border-0 border_start_select w-50 select_text"
                    value={Unit_Height_of_load}
                    onChange={(e) => {
                      setUnit_Height_of_load(e.target.value);
                    }}
                  >
                    <option value="Inch">Inch</option>
                    <option value="Linear feet">Linear feet</option>
                  </select>
                </div>
              </label>
              <div className="d-flex w-100">
                <div className="w-50">
                  <span className={Height_of_load ? "d-none" : "d-block"}>
                    {formErrors.Height_of_load && (
                      <span className="text-danger">
                        {formErrors.Height_of_load}
                      </span>
                    )}
                  </span>
                </div>
                <div className={Unit_Height_of_load ? "d-none" : "d-block"}>
                  {formErrors.Unit_Height_of_load && (
                    <span className="text-danger">
                      {formErrors.Unit_Height_of_load}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-3">
              <label className="w-100">
                <span className="label_text"> Width of load :</span>
                <div className="select_border w-100 d-flex p-2">
                  <input
                    type="number"
                    className="border-0 w-50 select_text"
                    value={Width_of_load}
                    onChange={(e) => {
                      setWidth_of_load(e.target.value);
                    }}
                    onInvalid={(F) =>
                      F.target.setCustomValidity("Enter width of load ")
                    }
                    onInput={(F) => F.target.setCustomValidity("")}
                    required
                  />
                  <div className="line"></div>
                  <select
                    className="border-0 border_start_select w-50 select_text"
                    value={Unit_Width_of_load}
                    onChange={(e) => {
                      setUnit_Width_of_load(e.target.value);
                    }}
                  >
                    <option value="Inch">Inch</option>
                    <option value="Linear feet">Linear feet</option>
                  </select>
                </div>
                <div className="d-flex w-100">
                  <div className="w-50">
                    <span className={Width_of_load ? "d-none" : "d-block"}>
                      {formErrors.Width_of_load && (
                        <span className="text-danger">
                          {formErrors.Width_of_load}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className={Unit_Width_of_load ? "d-none" : "d-block"}>
                    {formErrors.Unit_Width_of_load && (
                      <span className="text-danger">
                        {formErrors.Unit_Width_of_load}
                      </span>
                    )}
                  </div>
                </div>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-3">
              <label className="w-100">
                <span className="label_text">Length of load :</span>
                <div className="select_border w-100 d-flex p-2">
                  <input
                    type="number"
                    className="border-0 w-50 select_text"
                    value={Length_of_load}
                    onChange={(e) => {
                      setLength_of_load(e.target.value);
                    }}
                    onInvalid={(F) =>
                      F.target.setCustomValidity("Enter length of load")
                    }
                    onInput={(F) => F.target.setCustomValidity("")}
                    required
                  />
                  <div className="line"></div>
                  <select
                    className="border-0 border_start_select w-50 select_text"
                    value={Unit_Length_of_load}
                    onChange={(e) => {
                      setUnit_Length_of_load(e.target.value);
                    }}
                  >
                    <option value="Inch">Inch</option>
                    <option value="Linear feet">Linear feet</option>
                  </select>
                </div>
                <div className="d-flex w-100">
                  <div className="w-50">
                    <span className={Length_of_load ? "d-none" : "d-block"}>
                      {formErrors.Length_of_load && (
                        <span className="text-danger">
                          {formErrors.Length_of_load}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className={Unit_Length_of_load ? "d-none" : "d-block"}>
                    {formErrors.Unit_Length_of_load && (
                      <span className="text-danger">
                        {formErrors.Unit_Length_of_load}
                      </span>
                    )}
                  </div>
                </div>
              </label>
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
              style={{ margin: "17px 0px 0px 0px " }}
            >
              <label className="w-100">
                <span className="label_text"> Photo of load :</span>
                <div className="p-0 m-0">
                  <div className="btn-styles w-100 position-relative h-100">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(event) => setImage(event.target.files[0])}
                      ref={imageInputRef}
                      className="form-control form-control-lg bg pb-0"
                      onInvalid={(F) =>
                        F.target.setCustomValidity("Select a file")
                      }
                      onInput={(F) => F.target.setCustomValidity("")}
                      required
                    />
                  </div>
                </div>
                <div className={text ? "d-none" : "d-block"}>
                  {formErrors.text && (
                    <span className="text-danger">{formErrors.text}</span>
                  )}
                </div>
              </label>
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 volume_set_mt_sm position-relative"
              style={
                !text
                  ? { margin: "30px 0px 0px 0px" }
                  : { margin: "60px 0px 0px 0px" }
              }
            >
              <label className="w-100">
                <span className="label_text">Volume :</span>
                <div>
                  <input
                    type="text"
                    className="w-100 input_types"
                    value={Volume}
                    onChange={(e) => {
                      setVolume(e.target.value);
                    }}
                    readOnly
                  />
                </div>
              </label>
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4"
              style={{ margin: "17px 0px 0px 0px " }}
            >
              <label className="w-100">
                <span className="label_text"> Select material :</span>
                <div className="w-100 input_types border-0 p-0 m-0 px-2 mt-1">
                  <select
                    className="border-0 w-100 align-self-center border_start_select h-100"
                    name="option1"
                    onChange={handleSelectChange}
                    ref={selectRef}
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Select a material")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    required
                  >
                    <option value="" className="d-none"></option>

                    {options.map((category) => (
                      <optgroup
                        key={category.categoryName}
                        label={category.categoryName}
                      >
                        {category.children.map((child) => (
                          <option key={child.value} value={child.value}>
                            {child.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
                {/* <span className={!selectedMaterial ? "d-block" : "d-none"}>
                  {formErrors.Select_material && (
                    <span className="text-danger">
                      {formErrors.Select_material}
                    </span>
                  )}
                </span> */}
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-3">
              <label className="w-100">
                <span className="label_text"> Rate :</span>
                <div>
                  <input
                    type="number"
                    className="w-100 input_types"
                    value={Rate}
                    onChange={(e) => {
                      setRate(e.target.value);
                    }}
                    readOnly
                  />
                </div>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-3">
              <label className="w-100">
                <span className="label_text"> Total cost :</span>
                <div>
                  <input
                    type="number"
                    className="w-100 input_types"
                    value={Total_cost}
                    onChange={(e) => {
                      setTotal_cost(e.target.value);
                    }}
                    readOnly
                  />
                </div>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3 align-self-center ">
              <div className="Mode_of_payment_button ">
                <div>
                  <span className="label_text text-nowrap">
                    Mode of payment :
                  </span>
                  <br />
                </div>
                <div className="wrapper">
                  <input
                    type="radio"
                    value={"Card"}
                    name="select"
                    id="option-1"
                    checked={checkd}
                    onChange={(e) => {
                      setCheckd1(false);
                      setCheckd(true);
                      setMode_of_payment(e.target.value);
                    }}
                  />
                  <input
                    type="radio"
                    value={"Cash"}
                    name="select"
                    id="option-2"
                    checked={checkd1}
                    onChange={(e) => {
                      setCheckd(false);
                      setCheckd1(true);
                      setMode_of_payment(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="option-1"
                    className={
                      !Mode_of_payment ? "option bg-white" : "option option-1"
                    }
                  >
                    <span>Card</span>
                  </label>
                  <label
                    htmlFor="option-2"
                    className={
                      !Mode_of_payment ? "option bg-white" : "option option-2"
                    }
                  >
                    <span>Cash</span>
                  </label>
                </div>
              </div>
              <span className={Mode_of_payment ? "d-none" : "d-block"}>
                {formErrors.Mode_of_payment && (
                  <span className="text-danger">
                    {formErrors.Mode_of_payment}
                  </span>
                )}
              </span>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3">
              <label className="w-100">
                <span className="label_text text-nowrap me-2">
                  Enter email for receipt :{" "}
                </span>
                <div>
                  <input
                    type={"email"}
                    className="input_types w-100"
                    value={Email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                {formErrors.Email && (
                  <span className="text-danger">{formErrors.Email}</span>
                )}
              </label>
            </div>
            <div
              className={Mode_of_payment === "Cash" ? "d-block mt-3" : "d-none"}
            >
              <label className="w-100">
                <span className="label_text text-nowrap">Receiver name : </span>
                <div>
                  <input
                    type={"text"}
                    className="input_types w-100"
                    value={Receiver_name}
                    onChange={(e) => {
                      setReceiver_name(e.target.value);
                    }}
                  />
                  <span
                    className={
                      !Receiver_name ? "d-block text-danger" : "d-none"
                    }
                  >
                    {cashError}
                  </span>
                </div>
              </label>
            </div>
            <div className="d-flex justify-content-center py-3">
              <button className="submit_button">
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <div className="pb-4">
            <img src={Check} alt="" className="mx-auto d-block w-50 h-50" />
            <div className={!email ? "d-none " : "d-block"}>
              <p className="text-center fw-bold">
                Invoice sent at email: {email}
              </p>
            </div>
            <p className="text-center fw-bold">Your order number is {id}</p>
            <button
              onClick={() => {
                form();
                handleClose();
              }}
              className="btn border border-2 w-25 d-block mx-auto"
            >
              Ok
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default Forms;
