import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import LoginPage from "./pages/LoginPage/Login";
import OrderHistory from './pages/OrderHistory/OrderHistory';
import SubmitPage from './pages/SubmitPage/SubmitPage';
import './App.css';
import Credit from './pages/SubmitPage/Form/Credit/Credit';
// import useAuth from "./useAuth";


function RequireAuth({ children }) {
  return sessionStorage.getItem("authorization") ? children : <Navigate to="/loginPage" replace />;
}

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/loginPage" element={<LoginPage />} />
            <Route path="/submitPage" element={<SubmitPage />} />
            <Route path="/Card" element={<Credit />} />
            <Route path="/orderHistory" element={<RequireAuth><OrderHistory /></RequireAuth>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
